import React from 'react'
import '../styles/Image.css'
const Image = () => {
    return (
       
            <img className = "image" src="https://i.ibb.co/LCnXdyX/IMG-0313.jpg" alt="IMG-0313" border="0" ></img>
        
    )
}


export default Image
