import React from 'react'
import '../styles/About.css'
const About = () => {
    return (
        <div className='parent-container'>
           <div className='center-item amatic'>
                ABOUT
            </div>
            <div className='center-item'>
                <div className='text-container'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi erat lorem, bibendum in volutpat vel, accumsan id sapien. Integer venenatis fringilla dolor sit amet iaculis. Nunc at aliquet magna. Proin commodo condimentum vestibulum. Vestibulum gravida, nulla at fermentum fermentum, felis lorem ultrices ipsum, nec congue elit felis nec est. Sed at maximus nulla. Cras in lorem pharetra, rhoncus odio sit amet, dictum ante.
                </div>
            </div>
               
        </div>
    )
}

export default About
