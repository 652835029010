import React, { Component } from 'react'
import '../styles/Navbar.css'
//import ScrollButton from './ScrollButton'
 class Navbar extends Component {
    render() {
        function scroll()
        {
            console.log("get fucked")
        }
        return (
            // <div className='link-container'>
            //     <Link to = '/' className='link'>Home</Link>
            //     <Link to = '/about' className='link'>about</Link>
            // </div>
            <div className='link-container-parent'>
                <div className='link-container-left'>
                    <div className="link">
                        HOME
                    </div>
                    <div onClick={scroll} className="link">
                        ABOUT
                    </div>
                    
                    
                </div>
                <div className='link-container-right'>
                
                <div className="link">
                        VIDEOS
                    </div>
                    <div className="link">
                        LESSONS
                    </div>
                   
                    
                </div>
            </div>
        )
    }
}

export default Navbar;

