import Header from './components/Header'
import './styles/App.css'
import About from './components/About'
import Image from './components/Image'
function App() {

  return (
    <div>
      <Header/>
      <Image/>
      <About/>
    </div>
  );
}

export default App;
