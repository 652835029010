import React from 'react'
import Navbar from './Navbar'
import '../styles/Header.css'
const Header = () => {
    return (
       

       
        <div className = 'header'>
            <div className='title'>
                NYC Music Instruction
            </div>
            <Navbar/>
        </div>
      
    )
}

export default Header
